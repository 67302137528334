<template>
  <div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ElMessage } from 'element-plus'
import {
  bhLogin,
  bhAccountInfo,
  bhVipInfo,
  decryptControllerId
} from '@/api/user.js'
export default {
  name: 'bhlogin',
  data () {
    return {
      companyId: ''
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo',
      'busId'
    ])
  },
  created () {
    this.$store.dispatch('logout')
    const params = this.$route.query
    if (params && params.idToken) {
      bhLogin({
        encryption: params.idToken
      }).then(result => {
        if (result.code === 0) {
          const idToken = result.data.idToken
          if (idToken) {
            this.$store.dispatch('setIdToken', idToken)
            if (params && params.companyId) {
              decryptControllerId({
                encodeController: params.companyId
              }).then(result => {
                console.log(result)
                if (result.code === 0) {
                  this.companyId = result.data
                  this.getIdtokenUserInfo()
                } else {
                  ElMessage.error({ message: result.msg, offset: 60, duration: 0 })
                }
              }).catch(error => {
                console.log(error)
              })
            }
          }
        } else {
          ElMessage.error({ message: result.msg, offset: 60, duration: 0 })
        }
      }).catch(error => {
        console.log(error)
      })
    }
    // if (params && params.companyId) {
    //   decryptControllerId({
    //     encodeController: params.companyId
    //   }).then(result => {
    //     console.log(result)
    //     if (result.code === 0) {
    //       this.companyId = result.data
    //     } else {
    //       ElMessage.error({ message: result.msg, offset: 60, duration: 0 })
    //     }
    //   }).catch(error => {
    //     console.log(error)
    //   })
    // }
  },
  methods: {
    getIdtokenUserInfo () {
      const that = this
      bhAccountInfo().then(result => {
        if (result.code === 0) {
          const resultData = result.data
          bhVipInfo().then(results => {
            if (results.code === 0) {
              if (results.data.enableStatus === 0) {
                that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
                that.$store.dispatch('setVipData', JSON.stringify(results.data))
                that.$store.dispatch('setBusId', that.companyId)
                that.$router.push({ name: 'enterprise-details' })
              }
            } else {
              ElMessage.error({ message: result.msg, offset: 60, duration: 0 })
            }
          })
            .catch(error => {
              console.log(error)
            })
        } else {
          ElMessage.error({ message: result.msg, offset: 60, duration: 0 })
        }
      })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style>
</style>
